/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700&display=swap');

html, body {
    font-family: 'Heebo', sans-serif;
    scroll-behavior: smooth;
    @apply bg-black text-white overflow-x-hidden;
}